import React from "react";

import NavBar from "./components/NavBar";
import PageContent from "./components/PageContent";
import { LoggedContent } from "./contents/LoggedContent";
import { LoginContent } from "./contents/LoginContent";

import { useAuth0 } from "./auth0Context";

import "./App.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="App">
      <NavBar />
      <PageContent>
        {isAuthenticated ? <LoggedContent /> : <LoginContent />}
      </PageContent>
    </div>
  );
}

export default App;
