import React from "react";

import ContentBox from "../components/ContentBox";
import { Button } from "../components/Button";

import { useAuth0 } from "../auth0Context";

export const LoginContent = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <ContentBox title="Backend Login">
      <Button theme="master" onClick={() => loginWithRedirect({})}>
        Log in
      </Button>
    </ContentBox>
  );
};
