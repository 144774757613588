import React from "react";

import "./Input.scss";

export const Input = ({ name, label, val, setVal, handleChange }) => {
  // const [value, setValue] = useState(0.01);
  // const decLen = num => {
  //   const numArr = num
  //     .toString()
  //     .replace(".", ",")
  //     .split(",");
  //   return numArr.length === 2 ? numArr[1].length : 0;
  // };
  // const handleChange = e => {
  //   let val = e.target.value;
  //   if (val >= 0 && val < 100 && decLen(val) < 3) {
  //     setValue(val);
  //     setVal(val);
  //   }
  // };

  const handleClick = e => {
    e.target.select();
  };

  // const filterAndHandle = e => {
  //   handleChange(e);
  // };

  // useEffect(() => setValue(parseFloat(val).toFixed(2)), [val]);

  return (
    <>
      <label>{label}</label>
      <input
        type="number"
        name={name}
        id={name}
        size="4"
        min="10"
        max="99"
        step="0.01"
        onChange={e => handleChange(e)}
        value={val}
        onClick={e => handleClick(e)}
      />
      <span>€</span>
    </>
  );
};
