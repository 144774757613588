import React from "react";
import Loader from "react-loader-spinner";

import "./ContentBox.scss";

const ContentBox = ({ title, isLoading, children }) => (
  <article className="content-box">
    {isLoading && (
      <div className="overlay">
        <Loader type="Triangle" color="#aaa" height={60} width={60} />
      </div>
    )}
    <h2>{title}</h2>
    <div className="content">{children}</div>
  </article>
);

export default ContentBox;
