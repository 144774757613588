import React, { useState, useEffect } from "react";

import ContentBox from "../components/ContentBox";
import { PreviewBox } from "../components/PreviewBox";
import { Button } from "../components/Button";
import { Input } from "../components/Input";

import { useAppState } from "../appStateContext";

export const LoggedContent = () => {
  const { loading, prices, updatePrices } = useAppState();
  const [basePrice, setBasePrice] = useState(0.01);
  const [ninerPrice, setNinerPrice] = useState(0.01);

  useEffect(() => {
    setBasePrice(prices.filter(p => p.id === "base")[0].price);
    setNinerPrice(prices.filter(p => p.id === "999")[0].price);
  }, [prices]);

  return (
    <>
      <ContentBox title="Goldpreise eintragen" isLoading={loading}>
        <ul>
          <li>
            <Input
              name="999"
              label="999er Feingold"
              val={ninerPrice}
              handleChange={e => setNinerPrice(e.target.value)}
            />
          </li>
          <li>
            <Input
              name="base"
              label="999er Basis"
              val={basePrice}
              handleChange={e => setBasePrice(e.target.value)}
            />
          </li>
          <li>
            <Button
              theme="master"
              onClick={() => {
                updatePrices([
                  { id: "base", price: parseFloat(basePrice).toFixed(2) },
                  { id: "999", price: parseFloat(ninerPrice).toFixed(2) }
                ]);
              }}
            >
              Preise aktualisieren
            </Button>
          </li>
        </ul>
      </ContentBox>
      <PreviewBox />
    </>
  );
};
