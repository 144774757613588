import React from "react";

import { Button } from "./Button";

import { useAuth0 } from "../auth0Context";

import "./NavBar.scss";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <nav>
      <a href="https://www.goldankauf-turgay.de" target="_blank">
        <img
          alt="turgay logo"
          src="https://www.goldankauf-turgay.de/icon/turgay_logo_01.svg"
        />
      </a>
      {(!isAuthenticated && (
        <Button theme="slave" onClick={() => loginWithRedirect({})}>
          Log in
        </Button>
      )) || (
        <Button theme="slave" onClick={() => logout()}>
          Log out
        </Button>
      )}
    </nav>
  );
};

export default NavBar;
