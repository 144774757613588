import React from "react";

import "./Button.scss";

export const Button = ({ children, onClick, theme }) => (
  <button
    onClick={onClick}
    className={theme === "master" ? "--master" : "--slave"}
  >
    {children}
  </button>
);
