import React, { useEffect } from "react";

import ContentBox from "./ContentBox";

import { useAppState } from "../appStateContext";
import { useAuth0 } from "../auth0Context";

import "./PreviewBox.scss";

export const PreviewBox = () => {
  const { fetchPrices, loading, prices } = useAppState();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    isAuthenticated && fetchPrices();
    // eslint-disable-next-line
  }, []);

  return (
    <ContentBox title="Vorschau" isLoading={loading}>
      {prices && (
        <ul className="preview-ul">
          {prices.map((entry, index) => {
            const { id, label, price } = entry;
            return (
              id !== "base" && (
                <li key={index}>
                  <label>{label}</label>
                  <span>{`${parseFloat(price).toFixed(2)}€`}</span>
                </li>
              )
            );
          })}
        </ul>
      )}
    </ContentBox>
  );
};
