// src/index.js

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { Auth0Provider } from "./auth0Context";
import { AppStateProvider } from "./appStateContext";
import config from "./auth_config.json";
import * as serviceWorker from "./serviceWorker";

import "./index.scss";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const mockPricesData = [
  { id: "base", label: "", price: 39 },
  { id: "999", label: "999er Feingold", price: 41.8 }
];

const fetchPricesData = async () => {
  return new Promise(
    resolve => setTimeout(() => resolve(mockPricesData), 2000),
    reject => reject("ERR")
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <AppStateProvider fetchPricesData={fetchPricesData}>
      <App />
    </AppStateProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
