import React, { useState, useContext, createContext } from "react";
const initialPriceValues = [
  { id: "base", label: "999er Basis", price: 0 },
  { id: "999", label: "999er Feingold", price: 0 },
  { id: "916", label: "916er Gold", price: 0 },
  { id: "900", label: "900er Gold", price: 0 },
  { id: "750", label: "750er Gold", price: 0 },
  { id: "585", label: "585er Gold", price: 0 },
  { id: "333", label: "333er Gold", price: 0 }
];

const API_URL = "https://api.goldankauf-turgay.de/articles";
const LOCAL_URL = "http://localhost:3000/articles";

export const AppStateContext = createContext();
export const useAppState = () => useContext(AppStateContext);
export const AppStateProvider = ({ children, fetchPricesData }) => {
  const [prices, setPrices] = useState(initialPriceValues);
  const [loading, setLoading] = useState(false);

  const fetchPrices = async () => {
    setLoading(true);
    // const pricesData = await fetchPricesData();
    const result = await fetch(API_URL, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    const pricesData = await result.json();
    setPrices(pricesData);
    setLoading(false);
  };

  const updatePrices = async newPrices => {
    setLoading(true);
    console.log("NEW: ", newPrices);
    const result = await fetch(API_URL, {
      method: "PUT",
      body: JSON.stringify(newPrices),
      headers: {
        "Content-Type": "application/json"
      }
    });
    const pricesData = await result.json();
    setPrices(pricesData);
    setLoading(false);
  };

  return (
    <AppStateContext.Provider
      value={{ prices, loading, fetchPrices, updatePrices }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
